import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store"
import ModuleListStoreType from "@/views/PlatformAdmin/ModuleList/Store/indexType";

@Module({
    name:"ModuleList",
    store,
    dynamic: true,
})
export default class ModuleList extends VuexModule implements ModuleListStoreType{
    ModuleListSelect: any[] = [];
    get getModuleListSelect(){
        return this.ModuleListSelect
    }
    @Mutation
    SetModuleListSelect(data: any): void {
        this.ModuleListSelect = data
    }

}

export const ModuleListStore = getModule( ModuleList )
