import Storage from "@/util/Storage";
import {addSchoolModuleAdmin} from "@/Api/Platform/bascs";
import {Toast} from "vant";

// 添加学校模块
export function addSchoolModule(body:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    if (!body["schoolMenuList"]) {
        Toast("请选择功能菜单")
        return Promise.reject()
    }
    if (!body["code"]) {
        let userInfo:any = {};
        try{
            userInfo = JSON.parse(Storage.GetData_?.("UserData"))
        }catch (e) {
            userInfo = eval(Storage.GetData_?.("UserData"))
        }
        if ( userInfo.school ) body["code"] = userInfo.school;
        else {
            Toast("请选择添加学校")
            return Promise.reject()
        }
    }
    Toast.loading({ duration:0,message:"添加中~~~" })
    return Promise.resolve(addSchoolModuleAdmin({ userId,token },body).then(res=>{
        Toast.clear()
        return res.data
    }))
}
