import {delModuleAdmin, delSchoolModuleAdmin, getModuleAdmin} from "@/Api/Platform/bascs";
import Storage from "@/util/Storage";
import {Toast} from "vant";

// 获取功能列表
export function getModuleList(body:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");

    return Promise.resolve(getModuleAdmin({ userId,token },body).then(res=>{
        return res.data
    }))
}


// 删除功能
export function delModule(id:string|number = "") {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    Toast.loading({ duration:0,
    message:"删除中~~~"})
    return Promise.resolve(delModuleAdmin({ userId,token },{ id }).then(res=>{
        Toast.clear()
        return res.data
    }))
}

// 删除校区功能
export function delSchoolModule(id:string|number = "") {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    Toast.loading({ duration:0,
        message:"删除中~~~"})
    return Promise.resolve(delSchoolModuleAdmin({ userId,token },{ id }).then(res=>{
        Toast.clear()
        return res.data
    }))
}
